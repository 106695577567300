<script setup>
const { t } = useI18n({})
</script>

<template>
  <div class="w-screen overflow-hidden">
    <HomePart1 />
    <HomePart2 />
    <HomePart3 />

    <ClientOnly>
      <HomeProductDiscounted />
    </ClientOnly>

    <ClientOnly>
      <HomeProductSpotlight />
    </ClientOnly>

    <ClientOnly>
      <HomeAdviceSpotlight />
    </ClientOnly>

    <ClientOnly>
      <HomeRecentProducts />
    </ClientOnly>

    <ClientOnly>
      <HomeSocialFeed />
    </ClientOnly>
    <GinoPopup
      :button="t('popup.advice_button')"
      class=""
      link="/advice"
      :text="t('popup.advice')"
    />
  </div>
</template>

<style>
body {
  overflow-x: hidden
}
</style>
